import React, { Fragment } from 'react';
import ImageGallery from 'react-image-gallery';
import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Hidden, Visible } from 'react-grid-system';

const ImageGalleryCustom = styled(ImageGallery)`

`
const Image = styled.div`
  width: 100%;
  padding-top: 75%;
  position: relative;
  background-image: url("${props => props.src}");
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  ::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(6px);
  }
  ::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    background-image: url("${props => props.src}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }  
`
const IframeCont = styled.div`
  width: 100%;
  padding-top: 75%;
  position: relative;
`
const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  height:100%;
  width:100%;
  border: 0;
`

const InteractiveCont = styled.div`
  margin:0px;
  padding:0px;
  @media(min-width:768px){
    min-height: 50vh;
  }
`
const NavButton = styled.button`
  position: absolute;
  top: 50%;
  z-index: 500;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000;
  border: none;
  transition: 250ms ease;
  margin: 0 .5rem;
  color: #fff;
  cursor: pointer;
  @media(min-width:768px){

  }
  &:hover{
    filter: brightness(1.1);
  }
`
const NavButtonLeft = styled(NavButton)`

`
const NavButtonRight = styled(NavButton)`
  right: 0;
`

const InteractiveAsset = (item, interactive, provider)=> {

  return(
    <InteractiveCont>
      {
        item.provider
        ?(
          <IframeCont>
            <Iframe
              height="100%" width="100%"
              src={item.url}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
            />
          </IframeCont>
        )
        :(
          <Image src={item.url} />
        )
      }
    </InteractiveCont>
  )
}

export default ({ images, videos })=> {
  //console.log(images);
  //const gallery = images.map(item => ({ original: item.url, thumbnail: item.url }));
  const sortedVideos = videos.sort((a, b) => (a === b) ? 0 : a.interactive? -1 : 1)
  const gallery = [ ...sortedVideos, ...images].map(item => ({
    thumbnail: item.provider ? item.interactive ? "/360-degrees.svg" : "/video.svg" : item.url,
    thumbnailClass: "thumbnail-custom",
    renderItem: () => <InteractiveAsset {...item} />,
  }));  

  return(
    <Fragment>
      <ImageGalleryCustom
        items={gallery}
        showPlayButton={false}
        thumbnailPosition="bottom"
        renderLeftNav={(onClick, disabled)=> <NavButtonLeft onClick={onClick} disabled={disabled}><LeftOutlined /></NavButtonLeft>}
        renderRightNav={(onClick, disabled)=> <NavButtonRight onClick={onClick} disabled={disabled}><RightOutlined /></NavButtonRight>}
      />        
  </Fragment>
  )
}